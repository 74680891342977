import * as React from 'react'
import styled from 'styled-components/macro'

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButton as MuiListItemButton,
} from '@mui/material'

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.tokens.surfaceDim};
  z-index: 1;
  display: flex;
`

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.tokens.secondary};
    font-weight: ${(props) => (props.isCompanyName ? 'normal' : 'bold')};
  }
`

const OliSystemsText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.tokens.secondary};
  }
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

function Footer() {
  return (
    <Wrapper>
      <FooterContainer container spacing={0}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'row',
                margin: 5,
                padding: 0,
              }}
            >
              <ListItemButton
                target="_blank"
                href="https://www.my-oli.com/contact"
              >
                <ListItemText primary="Hilfe" />
              </ListItemButton>
              <ListItemButton
                target="_blank"
                href="https://www.my-oli.com/datenschutz-community"
              >
                <ListItemText primary="Datenschutz" />
              </ListItemButton>
              <ListItemButton
                target="_blank"
                href="https://www.my-oli.com/imprint"
              >
                <ListItemText primary="Impressum" />
              </ListItemButton>
            </List>
          </Grid>
          <Grid item>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'row',
                margin: 5,
                padding: 0,
              }}
            >
              <ListItemButton>
                <OliSystemsText
                  primary={`© ${new Date().getFullYear()} OLI Systems GmbH`}
                />
              </ListItemButton>
            </List>
          </Grid>
        </Grid>
      </FooterContainer>
    </Wrapper>
  )
}

export default Footer
