import * as React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  StyledUserNameAndMainColumn,
  StyledTableRow,
  SubTableHeaderCell,
  SubColumnHeading,
} from './components'

export default function UserTableHead() {
  const { t } = useTranslation()

  return (
    <TableHead
      sx={{
        borderTop: '2px solid',
        color: (theme) => theme.tokens.outlineVariant,
      }}
    >
      <TableRow
        sx={{
          borderBottom: '2px solid ',
          color: (theme) => theme.tokens.outlineVariant,
        }}
      >
        <StyledUserNameAndMainColumn
          colSpan={1}
          style={{
            borderRight: '1px solid',
            color: (theme) => theme.tokens.outlineVariant,
            width: 100,
          }}
        >
          <Typography variant="h4" align="left" style={{ fontWeight: 'bold' }}>
            {t('LBLUserName')}
          </Typography>
        </StyledUserNameAndMainColumn>
        <StyledUserNameAndMainColumn colSpan={6}>
          <Typography variant="h4" align="left" style={{ fontWeight: 'bold' }}>
            {t('LBLContracts')}
          </Typography>
        </StyledUserNameAndMainColumn>
      </TableRow>
      <StyledTableRow
        style={{
          borderBottom: '2px solid',
          color: (theme) => theme.tokens.outlineVariant,
        }}
      >
        <SubTableHeaderCell
          style={{
            borderRight: '1px solid',
            color: (theme) => theme.tokens.outlineVariant,
          }}
        />
        <TableRow>
          <SubTableHeaderCell style={{ minWidth: '12vw' }}>
            <SubColumnHeading variant="h4">
              {t('LBLContractsNumber')}
            </SubColumnHeading>
          </SubTableHeaderCell>
          <SubTableHeaderCell style={{ minWidth: '10vw' }}>
            <SubColumnHeading variant="h4">{t('LBLLocation')}</SubColumnHeading>
          </SubTableHeaderCell>
          <SubTableHeaderCell style={{ minWidth: '12vw' }}>
            <SubColumnHeading variant="h4">{t('LBLPartner')}</SubColumnHeading>
          </SubTableHeaderCell>
          <SubTableHeaderCell style={{ minWidth: '10vw' }}>
            <SubColumnHeading variant="h4">
              {t('LBLContractType')}
            </SubColumnHeading>
          </SubTableHeaderCell>
          <SubTableHeaderCell style={{ minWidth: '10vw' }}>
            <SubColumnHeading variant="h4">
              {t('LBLcontractStatus')}
            </SubColumnHeading>
          </SubTableHeaderCell>
          <SubTableHeaderCell style={{ minWidth: '10vw' }}>
            <div style={{ width: 110 }} />
          </SubTableHeaderCell>
        </TableRow>
      </StyledTableRow>
    </TableHead>
  )
}
