import { useMemo, useEffect, useState } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  MenuItem,
  TextField as MuiTextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material'
import shadows from '@mui/material/styles/shadows'
import Stack from '@mui/material/Stack'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import api from '../../../../Middleware/api/api'
import { useAuth } from '../../../../services/contexts'
import ErrorFeedback from '../../../../components/DataTable/Feedback'
/**
 * This component is the form that is used in edit workflow to update the details related to Production Plant in case of 'PRODUCER' contract.
 * This is the same component to get the 'CONSUMER' contract preferences.
 * The fields of the form changes as per contract type.
 *
 */
function ProductionPlantDetails({
  payload,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
}) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { token } = useAuth()
  const [showError, setShowError] = useState(false)
  const [streets, setStreets] = useState(
    [payload?.plantDetails?.plantAddress?.street] || []
  )

  useEffect(() => {
    if (submit > 0) {
      document.getElementById('subTwo').click()
    }
    setSubmit(0)
  }, [submit, setSubmit])
  const closeFeedback = () => {
    setShowError(false)
  }
  const handlePostalCodeChange = async (e, setFieldValue) => {
    const newPostalCode = e.target.value
    setFieldValue('postalCode', newPostalCode)

    if (newPostalCode.length === 5) {
      try {
        const { data } = await api.addressVerification(token, newPostalCode)
        if (data) {
          setStreets(data?.data?.streets || [])
          setFieldValue('city', data?.data?.city)
        } else {
          setFieldValue('postalCode', '')
          setFieldValue('city', '')
          setShowError(true)
        }
      } catch (error) {
        setFieldValue('city', '')
        setFieldValue('postalCode', '')
        setFieldValue('streetConsumer', [null])
        setShowError(true)
      }
    }
  }

  const handleSelectChange = (e, setFieldValue) => {
    setFieldValue('streetConsumer', e.target.value)
  }
  const initialValues = {
    contractType: payload?.contractType || '',
    preference: payload?.preference || 'NONE',
    plantName:
      payload?.contractType === 'CONSUMER'
        ? ''
        : payload?.plantDetails?.plantName,
    plantType:
      payload?.contractType === 'CONSUMER'
        ? 'NONE'
        : payload?.plantDetails?.plantType,
    addressOwner: payload?.plantDetails?.plantAddress?.addressOwner,
    city: payload?.plantDetails?.plantAddress?.city || '',
    postalCode: payload?.plantDetails?.plantAddress?.postalCode,
    installedPowerKw:
      payload?.contractType === 'CONSUMER'
        ? 0.0
        : payload?.plantDetails?.installedPowerKw || 0.0,
    commisioningDate: payload?.plantDetails?.commisioningDate || null,
    marketLocation: payload?.plantDetails?.marketLocation || '',
    meterNumber: payload?.plantDetails?.meterNumber || '',
    plantKey: payload?.plantDetails?.plantKey || '',
    operator: payload?.plantDetails?.netOperator || '',
    stadtWerkeKunden: payload?.stadtWerkeKunden,
    lastSupplier: payload?.plantDetails?.lastSupplier || '',
    costumerNumber: payload?.plantDetails?.costumerNumber || '',
    // here starts values for consumer
    streetConsumer: payload?.plantDetails?.plantAddress?.street || '',
    houseNrConsumer: payload?.plantDetails?.plantAddress?.houseNr || '',
    marketLocationConsumer: payload?.plantDetails?.marketLocation || '',
    meterNumberConsumer: payload?.plantDetails?.meterNumber || '',
    startOfDelivery: payload?.startOfDelivery || null,
    nextPossibleDate: payload?.nextPossibleDate || false,
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        contractType: Yup.string(),
        plantName: Yup.string().when('contractType', {
          is: (val) => val !== 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLPlantNameReq'))
              .label(t('LBLPlantName')),
        }),
        plantType: Yup.string().when('contractType', {
          is: (val) => val !== 'CONSUMER',
          then: () => Yup.string().required(t('LBLplantTypeReq')),
        }),
        addressOwner: Yup.string()
          .label(t('LBLBillingAddressOwner'))
          .nullable(),
        city: Yup.string().required(t('LBLCityReq')).label(t('city')),
        postalCode: Yup.number().when('contractType', {
          is: (val) => val !== 'CONSUMER',
          then: () =>
            Yup.number()
              .required(t('LBLPostalCodeError'))
              .label(t('PostalCode')),
        }),
        streetConsumer: Yup.string().when('contractType', {
          is: (val) => val === 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('lblStNameReq'))
              .typeError(t('LBLStreetError'))
              .label(t('lblStName')),
        }),
        houseNrConsumer: Yup.string().when('contractType', {
          is: (val) => val === 'CONSUMER',
          then: () =>
            Yup.string().required(t('LBLhouseNrReq')).label(t('LBLHouseNr')),
        }),
        installedPowerKw: Yup.number().when('contractType', {
          is: (val) => val !== 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLinstalledPowerKwReq'))
              .label(t('LBLInstalledOutput')),
        }),
        commisioningDate: Yup.date()
          .nullable()
          .when('contractType', {
            is: (val) => val !== 'CONSUMER',
            then: () =>
              Yup.date()
                .nullable()
                .required(t('LBLcommisioningDateReq'))
                .label(t('LBLCommissioningDate')),
          }),
        startOfDelivery: Yup.date()
          .nullable()
          .label(t('LBLStartOfDeliveryDate'))
          .when('nextPossibleDate', ([nextPossibleDate]) => {
            if (!nextPossibleDate) {
              return Yup.date().required(t('LBLStartOfDeliveryDateReq'))
            }
          }),
        marketLocation: Yup.string().when('contractType', {
          is: (val) => val !== 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLmarketLocationReq'))
              .label(t('LBLMarketLocation')),
        }),
        meterNumber: Yup.string().when('contractType', {
          is: (val) => val !== 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLmeterNumberReq'))
              .label(t('LBLMeterNumber')),
        }),
        marketLocationConsumer: Yup.string().when('contractType', {
          is: (val) => val === 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLmarketLocationReq'))
              .label(t('LBLMarketLocation')),
        }),
        meterNumberConsumer: Yup.string().when('contractType', {
          is: (val) => val === 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLmeterNumberReq'))
              .label(t('LBLMeterNumber')),
        }),
        plantKey: Yup.string().when('contractType', {
          is: (val) => val !== 'CONSUMER',
          then: () =>
            Yup.string().required(t('LBLplantKeyReq')).label(t('LBLPlantKey')),
        }),
        operator: Yup.string().when('contractType', {
          is: (val) => val !== 'CONSUMER',
          then: () =>
            Yup.string().required(t('LBLOperatorReq')).label(t('LBLOperator')),
        }),
        lastSupplier: Yup.string().when('contractType', {
          is: (val) => val === 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLLastSupplier'))
              .label(t('LBLLastSupplier')),
        }),
        costumerNumber: Yup.string().when('contractType', {
          is: (val) => val === 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLLastCustomerNum'))
              .label(t('LBLLastCustomerNum')),
        }),
        preference: Yup.string().when('contractType', {
          is: (val) => val === 'CONSUMER',
          then: () =>
            Yup.string()
              .required(t('LBLplantTypeReq'))
              .label(t('LBLPlantName')),
        }),
        stadtWerkeKunden: Yup.bool().label('LBLStadtWerkeKunden'),
        nextPossibleDate: Yup.bool().label(t('LBLNextPossibleDate')),
      }),
    [t]
  )
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      preference: values.preference || 'NONE',
      plantDetails: {
        plantName: values.plantName || '',
        plantType: values.plantType || 'NONE',
        plantAddress: {
          addressOwner: values.addressOwner,
          street: values.street || values.streetConsumer,
          houseNr: values.houseNr || values.houseNrConsumer,
          city: values.city,
          postalCode: values.postalCode,
        },
        installedPowerKw: values.installedPowerKw || 0.0,
        commisioningDate:
          format(new Date(values.commisioningDate), 'yyyy-MM-dd') || null,
        marketLocation: values.marketLocation || values.marketLocationConsumer,
        meterNumber: values.meterNumber || values.meterNumberConsumer,
        plantKey: values.plantKey,
        netOperator: values.operator,
        lastSupplier: values.lastSupplier || '',
        costumerNumber: values.costumerNumber || '',
      },
      startOfDelivery:
        format(new Date(values.startOfDelivery), 'yyyy-MM-dd') || null,
      stadtWerkeKunden: values.stadtWerkeKunden,
      nextPossibleDate: values.nextPossibleDate,
    }

    setPayload(payload)
    confirmPayload()
    actions.setSubmitting(false)
    actions.setTouched({}, false)
    actions.setErrors({})
    actions.setFieldError({})
    actions.setFieldTouched({}, false, false)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ errors, touched, values, setFieldValue, isValid }) => (
          <Form noValidate>
            <>
              {setCanNext(touched && isValid)}

              {payload?.contractType === 'CONSUMER' ? (
                <>
                  <Stack
                    mt={5}
                    mb={values.nextPossibleDate ? 5 : 0}
                    direction="row"
                    spacing={8}
                  >
                    <MuiTextField
                      select
                      required
                      id="preference"
                      name="preference"
                      open={open}
                      fullWidth
                      margin="none"
                      label={t('LBLPreference')}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      defaultValue={payload?.preference}
                      value={values.preference}
                      onChange={(event) =>
                        setFieldValue('preference', event.target.value)
                      }
                      sx={{ width: '500px' }}
                    >
                      <MenuItem value="HYDRO_POWER">
                        {t('LBLHydroPower')}
                      </MenuItem>
                      <MenuItem value="SOLAR_POWER">
                        {t('LBLSolarPower')}
                      </MenuItem>
                      <MenuItem value="BIO_MASS">{t('LBLBioMass')}</MenuItem>
                      <MenuItem value="WIND_ENERGY">
                        {t('LBLWindEnergy')}
                      </MenuItem>
                      <MenuItem value="NONE">{t('LBLNoPreference')}</MenuItem>
                    </MuiTextField>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {!values.nextPossibleDate ? (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="de"
                        >
                          <DatePicker
                            label={t('LBLStartOfDeliveryDate')}
                            //minDate={dayjs(minStartDate)}
                            PaperProps={{
                              sx: {
                                boxShadow: shadows[3],
                              },
                            }}
                            value={values.startOfDelivery}
                            id={'startOfDelivery'}
                            onChange={(newValue) => {
                              setFieldValue(
                                'startOfDelivery',
                                newValue ? newValue.toDate() : null
                              )
                            }}
                            renderInput={(params) => (
                              <Field
                                component={TextField}
                                {...params}
                                required
                                name="startOfDelivery"
                                margin="none"
                                autoComplete="off"
                                helperText={
                                  touched.startOfDelivery &&
                                  errors.startOfDelivery
                                }
                                error={
                                  errors.startOfDelivery &&
                                  touched.startOfDelivery
                                }
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'TT.MM.JJJJ',
                                }}
                                sx={{ width: '500px' }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      ) : undefined}
                      <FormControlLabel
                        control={
                          <Field
                            component={Checkbox}
                            type="checkbox"
                            id="nextPossibleDate"
                            name="nextPossibleDate"
                            onChange={(event) => {
                              setFieldValue(
                                'nextPossibleDate',
                                event.target.checked
                              )
                            }}
                            checked={values.nextPossibleDate}
                          />
                        }
                        label={t('LBLNextPossibleDate')}
                      />
                    </div>
                  </Stack>

                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="addressOwner"
                      name="addressOwner"
                      label={t('LBLAddressOwner')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      required
                      margin="none"
                      component={TextField}
                      id="postalCode"
                      name="postalCode"
                      label={t('PostalCode')}
                      value={values.postalCode}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      onChange={(e) => handlePostalCodeChange(e, setFieldValue)}
                    />
                    <MuiTextField
                      select
                      fullWidth
                      margin="none"
                      id="streetConsumer"
                      name="streetConsumer"
                      label={t('lblStName')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      value={values.streetConsumer}
                      onChange={(e) => handleSelectChange(e, setFieldValue)}
                      error={Boolean(
                        errors.streetConsumer && touched.streetConsumer
                      )}
                      helperText={
                        touched.streetConsumer && errors.streetConsumer
                      }
                    >
                      {streets.map((street) => (
                        <MenuItem key={street} value={street}>
                          {street}
                        </MenuItem>
                      ))}
                    </MuiTextField>
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="houseNrConsumer"
                      name="houseNrConsumer"
                      label={t('LBLHouseNr')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                    <Field
                      required
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="city"
                      name="city"
                      label={t('city')}
                      value={values.city}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                  </Stack>

                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="lastSupplier"
                      name="lastSupplier"
                      label={t('LBLLastSupplier')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      sx={{ width: '500px' }}
                    />
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="costumerNumber"
                      name="costumerNumber"
                      label={t('LBLLastCustomerNum')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      sx={{ width: '500px' }}
                    />
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      required
                      margin="none"
                      component={TextField}
                      id="marketLocationConsumer"
                      name="marketLocationConsumer"
                      label={t('LBLMarketLocation')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />

                    <Field
                      fullWidth
                      required
                      margin="none"
                      component={TextField}
                      id="meterNumberConsumer"
                      name="meterNumberConsumer"
                      label={t('LBLMeterNumber')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                  </Stack>

                  {/* this is checkbox for condition acceptence. */}

                  <FormControlLabel
                    control={
                      <Field
                        component={Checkbox}
                        type="checkbox"
                        id="stadtWerkeKunden"
                        name="stadtWerkeKunden"
                        onChange={(event) => {
                          setFieldValue(
                            'stadtWerkeKunden',
                            event.target.checked
                          )
                        }}
                        checked={values.stadtWerkeKunden}
                      />
                    }
                    label={t('LBLStadtWerkeKunden')}
                  />
                </>
              ) : (
                <>
                  <Stack my={5} direction="row" alignItems="center" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="plantName"
                      name="plantName"
                      label={t('LBLPlantName')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      sx={{ width: '500px' }}
                    />
                    <MuiTextField
                      select
                      required
                      id="plantType"
                      name="plantType"
                      open={open}
                      fullWidth
                      margin="none"
                      label={t('LBLPlantType')}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      defaultValue={payload?.plantType}
                      value={values.plantType}
                      onChange={(event) =>
                        setFieldValue('plantType', event.target.value)
                      }
                      sx={{ width: '500px' }}
                    >
                      <MenuItem value="HYDRO_POWER">
                        {t('LBLHydroPower')}
                      </MenuItem>
                      <MenuItem value="SOLAR_POWER">
                        {t('LBLSolarPower')}
                      </MenuItem>
                      <MenuItem value="BIO_MASS">{t('LBLBioMass')}</MenuItem>
                      <MenuItem value="WIND_ENERGY">
                        {t('LBLWindEnergy')}
                      </MenuItem>
                    </MuiTextField>
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="addressOwner"
                      name="addressOwner"
                      label={t('LBLAddressOwner')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                    <Field
                      fullWidth
                      required
                      margin="none"
                      component={TextField}
                      id="postalCode"
                      name="postalCode"
                      label={t('PostalCode')}
                      value={values.postalCode}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      onChange={(e) => handlePostalCodeChange(e, setFieldValue)}
                    />
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      required
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="city"
                      name="city"
                      label={t('city')}
                      value={values.city}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      sx={{ width: '500px' }}
                    />
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="de"
                    >
                      <DatePicker
                        label={t('LBLStartOfDeliveryDate')}
                        //minDate={dayjs(minStartDate)}
                        PaperProps={{
                          sx: {
                            boxShadow: shadows[3],
                          },
                        }}
                        value={values.startOfDelivery}
                        id={'startOfDelivery'}
                        onChange={(newValue) => {
                          setFieldValue(
                            'startOfDelivery',
                            newValue ? newValue.toDate() : null
                          )
                        }}
                        renderInput={(params) => (
                          <Field
                            component={TextField}
                            {...params}
                            required
                            name="startOfDelivery"
                            margin="none"
                            autoComplete="off"
                            helperText={
                              touched.startOfDelivery && errors.startOfDelivery
                            }
                            error={
                              errors.startOfDelivery && touched.startOfDelivery
                            }
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'TT.MM.JJJJ',
                            }}
                            sx={{ width: '500px' }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="installedPowerKW"
                      name="installedPowerKw"
                      label={t('LBLInstalledOutput')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      sx={{ width: '500px' }}
                    />
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="de"
                    >
                      <DatePicker
                        label={t('LBLCommissioningDate')}
                        //minDate={dayjs(minStartDate)}
                        PaperProps={{
                          sx: {
                            boxShadow: shadows[3],
                          },
                        }}
                        value={values.commisioningDate}
                        id={'commisioningDate'}
                        onChange={(newValue) => {
                          setFieldValue(
                            'commisioningDate',
                            newValue ? newValue.toDate() : null
                          )
                        }}
                        renderInput={(params) => (
                          <Field
                            component={TextField}
                            {...params}
                            required
                            name="commisioningDate"
                            margin="none"
                            autoComplete="off"
                            helperText={
                              touched.commisioningDate &&
                              errors.commisioningDate
                            }
                            error={
                              errors.commisioningDate &&
                              touched.commisioningDate
                            }
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'TT.MM.JJJJ',
                            }}
                            sx={{ width: '500px' }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      required
                      margin="none"
                      component={TextField}
                      id="marketLocation"
                      name="marketLocation"
                      label={t('LBLMarketLocation')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />

                    <Field
                      fullWidth
                      required
                      margin="none"
                      component={TextField}
                      id="meterNumber"
                      name="meterNumber"
                      label={t('LBLMeterNumber')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      required
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="plantKey"
                      name="plantKey"
                      label={t('LBLPlantKey')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                    <Field
                      fullWidth
                      required
                      margin="none"
                      component={TextField}
                      id="operator"
                      name="operator"
                      label={t('LBLOperator')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                  </Stack>
                </>
              )}

              <Button
                sx={{ display: 'none' }}
                id="subTwo"
                type="submit"
              ></Button>
            </>
          </Form>
        )}
      </Formik>
      <ErrorFeedback
        open={showError}
        title={t('LBLErrorTitle')}
        severity="warning"
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {t('LBLAuwAreaError')}
          </Typography>
        }
        handleClose={closeFeedback}
      />
    </>
  )
}
ProductionPlantDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}
export default ProductionPlantDetails
